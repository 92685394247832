.no_scrollbars {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.no_scrollbars::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
a {
    cursor: pointer;
}
.change-enter {
    opacity: 0;
}
.change-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}